@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap');

.App {
  text-align: center;
  font-family: 'Lora', serif;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1 !important;
}

video {
  object-fit: cover;
}

.App-logo {
  height: 7vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #000000cf;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white !important;
  z-index: 5 !important;
  position: relative;
  font-family: 'Lora', serif;
}

.App-link {
  color: #fff;
  text-decoration: none;
  font-family: 'Lora', serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}